import React, {useCallback, useState, useEffect} from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import Widgets from '../components/widgets/Widgets' 
import ChevronIcon from "../assets/svg/inline/ic_big_chevron_right.svg"
import formatPhoneNumber from '../utils/formatPhoneNumber' 
import Seo from 'gatsby-plugin-wpgraphql-seo';
import InternalShapes from "../components/widgets/InternalShapes";
import AdditionalDownloads from "../components/widgets/AdditionalDownloads";

export default function Conveyancers({ data, location }) {
  const page = data.allWpPage.nodes[0];
  const additionalContent = page.conveyancers?.conveyancersAdditionalContent;
  const additionalDownloads = page.additionalDownloads;
  const conveyancers = data.allWpConveyancer?.nodes.sort((a, b) => a.title.localeCompare(b.title));//sort the conveyancers by title
  const [filteredConveyancers, setFilteredConveyancers] = useState(conveyancers);

  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = 10;

  //for controlling form elements
  const defaultFormValues = {search:'', representation:''}
  const [formValues, setFormValues] = useState(defaultFormValues);

  /**
   * Called when a form field changes
   */
  const handleChange = function(event) {

    var newValues = { ...formValues };

	newValues[event.target.name] = event.target.value;
	
	setFormValues(newValues);
  }

  /*
   * When the form is submitted via the enter key
   */
  const handleSubmit = function(event) {
    event.preventDefault();
	//submitForm()
  }

  /*
   * When we select an area from the list on desktop
   
  const selectRepresentation = function(area) {
    
    var newValues = { ...formValues };

	if (formValues.area===area) {
		newValues['representation'] = '';
	} else {
		newValues['representation'] = area;
	}
	
	setFormValues(newValues);
  }*/

  /*if we've just navigated from a child page we might want to select a section*/
  useEffect(() => {
    if (typeof location?.state?.page != 'undefined') {
		//console.log('setting page number to ', location.state.page);
      setPageNumber(location.state.page);
    }
  }, [location]);

  /*
   * Update the list when the form changes
   */
  useEffect(() => {
	if (!conveyancers || conveyancers.length===0) {
		return null;
	}
    let filteredConveyancers = [];
	
	conveyancers.forEach(function(conveyancer) {
	  let includeThisItem = true;
	  //check if we have a postcode search set
      if (formValues.search!=='') {
		//extract the leading letters from the user postcode using regular expression
		let match = formValues.search.toUpperCase().match(/^[A-Z]+/),
			postcodeMatch = false,
			nameMatch = false;
		if (match && conveyancer.conveyancers.conveyancerPostcode?.toUpperCase().indexOf(match[0])===0) {
		  postcodeMatch = true;	
		}	
	    if (conveyancer.title?.toUpperCase().indexOf(formValues.search.toUpperCase())>-1) {
			nameMatch = true;
	    }
		if (!postcodeMatch && !nameMatch) {
			includeThisItem = false;
		}
      }

	  //if (includeThisItem && formValues.representation!=='' && conveyancer.conveyancerRepresentation!==formValues.representation) {
	  //	includeThisItem = false;
	  //}

	  if (includeThisItem) {
	    filteredConveyancers.push(conveyancer);
	  }

	  //setFilteredConveyancers(filteredConveyancers);

	  //take a slice for the pagination
	  let offset = (itemsPerPage*(pageNumber-1));
	  setFilteredConveyancers(filteredConveyancers.slice(offset, offset+itemsPerPage));
	});
  }, [conveyancers, formValues, itemsPerPage, pageNumber]);

  const getPagination = useCallback((items) => {
	let numPages = Math.floor(items.length / itemsPerPage),
		pages = [];
	if (numPages!==1) {
		if (pageNumber!==1) {
		  pages.push(<li key="back"><Link to={page.uri} state={{ page: (pageNumber-1) }} className="back" aria-label="Previous page"><ChevronIcon /></Link></li>)
		}

		if (numPages>10 && pageNumber>1) {
		  pages.push(<li key={`page1`}><Link to={page.uri} state={{ page: 1 }}><span>1</span></Link></li>)
		  if (pageNumber>2) {
			pages.push(<li key={`page1b`}>...</li>)
		  }
		}
		
		for (let i=1; i<=numPages; i++) {
		  if (numPages<=10 || i===pageNumber/*Math.abs(i - pageNumber)<2*/) {
			pages.push(<li key={`page${i}`}><Link to={page.uri} state={{ page: i }} className={pageNumber===i ? 'active ': ''}><span>{i}</span></Link></li>)
		  }
		}
		
		if (numPages>10 && pageNumber<numPages) {
		  if (pageNumber< (numPages-1) ) {
			pages.push(<li key={`page${numPages}b`}>...</li>)
		  }
		  pages.push(<li key={`page${numPages}`}><Link to={page.uri} state={{ page: numPages }}><span>{numPages}</span></Link></li>)
		}
		
		if (pageNumber<numPages) {
		  pages.push(<li key="forward"><Link to={page.uri} state={{ page: (pageNumber+1) }} className="forward" aria-label="Next page"><ChevronIcon /></Link></li>)
		}
	}
	
	return pages;
  }, [page.uri, pageNumber]);

  return (
    <Layout className="page-conveyancers">	
      <Seo post={page} />
	  <article>
	    <header className="page-widgets">
	      <Hero widget={{layout : 'inner', title: (page.title), content: page.content}} />
	    </header>

		<div className="columns internal">
			<aside>
				<form onSubmit={handleSubmit}>
					<p>
						<span className="search-wrap">
							<input type="text" name="search" autoComplete="off" placeholder="Name or postcode" value={formValues.search} onChange={handleChange} />
							<button type="submit" className="plain" aria-label="Submit search"></button>
						</span>
					</p>
					{/*
					<p>
						<select name="representation" value={formValues.area} onChange={handleChange}>
							<option value="">Representation type</option>
						    <option value="separate">Separate representation</option>
						    <option value="joint">Joint representation</option>						    
						</select>
					</p>
					
					<ul>
					  <li className={formValues.representation==='separate' ? 'active' : ''}><button type="button" className="plain" onClick={() => selectRepresentation('separate')}>Separate representation</button></li>
					  <li className={formValues.representation==='joint' ? 'active' : ''}><button type="button" className="plain" onClick={() => selectRepresentation('joint')}>Joint representation</button></li>
					</ul>*/}
				</form>
				{additionalContent && (
					<div className="additional" dangerouslySetInnerHTML={{ __html: additionalContent }} />
				)}
			</aside>
			<div className="conveyancers">
			  {
				filteredConveyancers.length
				? <>
				  {filteredConveyancers.map((item, i) => (
				    <div className="item" key={`conveyancer${i}`}>	
						{
							i===(conveyancers.length-1) && conveyancers.length>4
							? <InternalShapes page="conveyancers" widget="Conveyancers" />
							: null
						}	
						<h2>{item.title}</h2>
						<div className="contacts">
							{
							  item.representations?.nodes?.length
						      ? <p className="representation">{item.representations.nodes[0].name}</p>	
							  : null
							}				
							<p className="sar-num">SRA No: {item.conveyancers.conveyancerSra}</p>	
						</div>				
						<div className="contacts">
							{item.conveyancers.conveyancerPhone && (
							  <p className="phone">Call <br /><a href={formatPhoneNumber(item.conveyancers.conveyancerPhone)}>{item.conveyancers.conveyancerPhone}</a></p>
						    )}
							{item.conveyancers.conveyancerEmail && (
							  <p className="email">Email <br /><a href={`mailto:${item.conveyancers.conveyancerEmail}`}>{item.conveyancers.conveyancerEmail}</a></p>
						    )}
							{item.conveyancers.conveyancerAddress && (
							  <p className="address">Address <br />
								<strong> {item.conveyancers.conveyancerAddress}
								  {item.conveyancers.conveyancerPostcode && (<>, {item.conveyancers.conveyancerPostcode}</>)}
								</strong>
							  </p>
						    )}
						</div>
				    </div>
			      ))}
					<nav>
					  <ul>
				  		{getPagination(conveyancers)}
					  </ul>
				    </nav>
				  </>
				: <p className="no-matches">No conveyancers found</p>
				}
			
				<AdditionalDownloads title={additionalDownloads.downloadsTitle} downloads={additionalDownloads.downloads} />
				
			</div>
		</div>
				
		<Widgets widgets={page.widgets?.pageWidgets} />
		
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
	    content
    	uri
        ...PageSEOFragment
	    conveyancers {
	      conveyancersAdditionalContent
	    }
		...WidgetFragment
	    additionalDownloads {
		  downloadsTitle
	      downloads {
	        download
	      }
	    }
      }
    }
	allWpConveyancer {
      nodes {
        title
        conveyancers {
          conveyancerSra
          conveyancerPostcode
          conveyancerPhone
          conveyancerEmail
          conveyancerAddress
        }
        representations {
          nodes {
            name
          }
        }
      }
    }
  }
`